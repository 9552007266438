import React from "react";
import { PropTypes } from "prop-types";
import pluralize from "pluralize";
import className from "classnames";
import { inject, observer } from "mobx-react";
import { CheckboxField, FlexCol, Text } from "wm-ui-toolkit";
import { AppContext } from "../../context/AppProvider";
import { rootStorePropTypes } from "../../proptypes/stores";
import { CreateForm } from "../../controls/CreateForm/CreateForm";
import { withRouter } from "../../utils/withRouter";
import { withTranslations } from "../../utils/withTranslations";
import { ConfirmDuplicatesModal } from "./ConfirmDuplicatesModal/ConfirmDuplicatesModal";
import * as duplicatesService from "../../requests/duplicates/duplicates";
import * as campaignLeadsService from "../../requests/campaigns/leads";

import "./AddLeadForm.scss";

const DELAY = 1000;
const FRESH_LEAD_STATUS = 'fresh';
const RECEIVED_LEAD_STATUS = 'received_by_sales';

@withTranslations
@withRouter
@inject("rootStore")
@observer
export class AddLeadForm extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    navigate: PropTypes.func,
    onClose: PropTypes.func,
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const { rootStore } = props;
    const { defaultLeadSlideoutProps } = rootStore;
    const { address, name } = defaultLeadSlideoutProps;

    this.state = this.getDefaultState({ address, name });
  }

  getDefaultState = (overrideState = {}) => {
    return {
      addAnotherLead: false,
      totalLeadsAddedCount: 0,
      address: "",
      duplicatePayload: {},
      duplicates: [],
      duplicatesCheckPromise: null,
      isModalOpen: false,
      isRefreshing: false,
      name: null,
      payload: {},
      ...overrideState,
    };
  };

  componentDidUpdate() {
    const { isRefreshing } = this.state;
    if (isRefreshing) {
      this.setState({ isRefreshing: false });
    }
  }

  submitLead = async (payload) => {
    const { addAnotherLead, totalLeadsAddedCount } = this.state;
    const { navigate, rootStore, t } = this.props;
    const { campaign } = this.context;
    const { leadLabel } = campaign;

    let campaignId = campaign.id;
    if (payload.campaignId) {
      campaignId = payload.campaignId;
    }

    try {
      const createdLead = await campaignLeadsService.postLead(
        campaignId,
        payload
      );

      rootStore.leadsStore.setCachedLeads([createdLead]);
      rootStore.funnelStore.addLeadToFunnelColumn(
        createdLead,
        createdLead.attributes.status
      );

      const newtotalLeadsAddedCount = totalLeadsAddedCount + 1;

      if (addAnotherLead) {
        this.setState(
          this.getDefaultState({
            totalLeadsAddedCount: newtotalLeadsAddedCount,
            addAnotherLead,
            isRefreshing: true,
          })
        );
      } else {
        navigate(`/leads/${createdLead.id}`);
      }
    } catch (err) {
      rootStore.bannerStore.addBanner(
        "red",
        t("common:layouts.slideout.failed", { leadLabel }),
        t("common:layouts.slideout.failedMessage", { leadLabel })
      );
    }
  };

  checkForDuplicates = () => {
    const { duplicatePayload } = this.state;
    const { campaign, currentUser } = this.context;
    const { id } = campaign;
    const { authToken } = currentUser;

    duplicatePayload.token = authToken;
    duplicatePayload.campaignId = id;

    const promise = new Promise((resolve) => {
      duplicatesService
        .checkForDuplicates(currentUser.authToken, duplicatePayload)
        .then((duplicates) => {
          const { leads } = duplicates;
          this.setState({ duplicates: leads });
          resolve({ success: true, leads });
        })
        .catch((err) => {
          resolve({ success: false, error: err });
        });
    });

    this.setState({ duplicatesCheckPromise: promise });
    return promise;
  };

  onAddressChange = (payload) => {
    const { geocodedInfo, value } = payload;
    const { duplicatePayload, name } = this.state;

    const { latitude, longitude } = geocodedInfo;

    const newDuplicatePayload = {
      ...duplicatePayload,
      latitude,
      longitude,
    };

    this.setState({
      address: value,
      duplicatePayload: newDuplicatePayload,
    });

    const duplicatesAreEnabled = this.duplicatesAreEnabled();

    if (duplicatesAreEnabled && name && latitude && longitude) {
      this.checkForDuplicates();
    }
  };

  onNameChange = (payload) => {
    const { duplicatePayload } = this.state;
    const name = payload.value;
    const { latitude, longitude } = duplicatePayload;

    const newDuplicatePayload = {
      ...duplicatePayload,
      name,
    };

    clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(() => {
      this.setState({ name });
      this.setState({ duplicatePayload: newDuplicatePayload });

      if (this.duplicatesAreEnabled() && latitude && longitude) {
        this.checkForDuplicates();
      }
    }, DELAY);
  };

  onSubmit = async (payload) => {
    const { duplicates, duplicatesCheckPromise } = this.state;

    if (duplicatesCheckPromise) {
      await duplicatesCheckPromise;
    }

    this.setState({ payload });

    const duplicatesAreEnabled = this.duplicatesAreEnabled();

    if (duplicatesAreEnabled && duplicates.length > 0) {
      this.openModal();
    } else {
      await this.submitLead(payload);
    }
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  duplicatesAreEnabled = () => {
    const { campaign } = this.context;
    const { duplicateSettings } = campaign;

    return duplicateSettings !== "disabled";
  };

  getPlatformsVisibleToUser = () => {
    const { currentUser } = this.context;

    if (currentUser.type === "Manager" || currentUser.type === "Salesperson") {
      return ["web", "both"];
    } else {
      return ["both"];
    }
  };

  getLeadStatusAfterCreation = () => {
    const { currentUser } = this.context;

    if (currentUser.autoAssignLeads) {
      return RECEIVED_LEAD_STATUS;
    } else {
      return FRESH_LEAD_STATUS;
    }
  }

  isFieldRequired = (field) => {
    const expectedLeadStatus = this.getLeadStatusAfterCreation();
    const { required_for_status: requiredForStatus } = field;

    return requiredForStatus && requiredForStatus.includes(expectedLeadStatus);
  }

  getFields = () => {
    const { rootStore, t } = this.props;
    const { address } = this.state;
    const { defaultLeadSlideoutProps } = rootStore;
    const { campaign, currentUser } = this.context;
    const { amountVisible } = currentUser;
    const { amountUnit, customFields, leadLabel, leadNameLabel } = campaign;

    const staticFields = [
      {
        name: leadNameLabel,
        id: "name",
        required: true,
        defaultValue: defaultLeadSlideoutProps.name,
        type: "text",
        onChange: this.onNameChange,
      },
      {
        name: t("common:layouts.slideout.address", { leadLabel }),
        id: "address",
        required: false,
        defaultValue: address,
        type: "address",
        onChange: this.onAddressChange,
      },
      ...(amountVisible ? [
        {
          name: t("common:layouts.slideout.amount", { unit: amountUnit }),
          id: "amount",
          required: false,
          defaultValue: defaultLeadSlideoutProps.amount,
          type: "number",
        }
      ] : []),
      {
        name: t("common:layouts.slideout.photo", { leadLabel }),
        id: "photo_urls",
        required: false,
        defaultValue: defaultLeadSlideoutProps.photoUrls,
        type: "photo",
        multiple: true,
      },
      {
        name: t("common:layouts.slideout.description"),
        id: "description",
        defaultValue: defaultLeadSlideoutProps.description,
        required: false,
        type: "textarea",
      },
    ];

    if (defaultLeadSlideoutProps.parentLeadId) {
      staticFields.push({
        id: "parent_lead_id",
        defaultValue: defaultLeadSlideoutProps.parentLeadId,
        type: "hidden",
      });
    }

    const visiblePlatforms = this.getPlatformsVisibleToUser();
    const visibleCustomFields = customFields.filter((field) => {
      return visiblePlatforms.includes(field.platform) && field.ui === "all";
    });

    const defaultCustomFields = visibleCustomFields
      .filter((field) => field.type != "table")
      .map((field) => {
        const defaultField = { ...field };
        defaultField.customField = true;
        defaultField.required = this.isFieldRequired(field);

        let defaultValue;
        if (defaultLeadSlideoutProps.customFields) {
          defaultValue = defaultLeadSlideoutProps.customFields[field.id];
        } else {
          defaultValue = field.default;
        }

        defaultField.defaultValue = defaultValue;

        return defaultField;
      });

    return staticFields.concat(defaultCustomFields);
  };

  getAfterSubmitAction() {
    const { onClose } = this.props;
    const { addAnotherLead, duplicates } = this.state;

    const duplicatesAreEnabled = this.duplicatesAreEnabled();

    const willShowDuplicatesModalBeforeSubmit =
      duplicatesAreEnabled && duplicates.length > 0;

    if (willShowDuplicatesModalBeforeSubmit || addAnotherLead) {
      return null;
    }

    return onClose;
  }

  onAddMoreChange = ({ isChecked }) => {
    this.setState({ addAnotherLead: isChecked });
  };

  renderAddMoreCheckbox = () => {
    const { t } = this.props;
    const { addAnotherLead, totalLeadsAddedCount } = this.state;
    const { campaign } = this.context;
    const { leadLabel } = campaign;

    let label;
    if (totalLeadsAddedCount > 0) {
      label = t("common:leadsAdded", {
        count: totalLeadsAddedCount,
        leadLabel: pluralize(leadLabel, totalLeadsAddedCount),
      });
    } else {
      label = t("common:addMore", { leadLabel });
    }

    return (
      <CheckboxField
        data-testid="AddLeadForm__AddMoreLeads"
        onChange={this.onAddMoreChange}
        isChecked={addAnotherLead}
        label={label}
      />
    );
  };

  render() {
    const { onClose, rootStore, t } = this.props;
    const { addLeadSlideoutOpen } = rootStore;
    const { campaign } = this.context;
    const { leadLabel } = campaign;
    const { duplicates, isModalOpen, isRefreshing, payload } = this.state;

    const showDuplicateDetectedMessage = duplicates.length > 0;
    const AddLeadFormDuplicateMessageClass = className(
      "AddLeadForm__duplicateMessage",
      {
        "duplicateMessage--visible": showDuplicateDetectedMessage,
      }
    );

    return (
      <>
        {addLeadSlideoutOpen && (
          <FlexCol alignItems="center" className="AddLeadForm">
            <div className="AddLeadForm__FormContainer">
              <Text className={AddLeadFormDuplicateMessageClass}>
                {t("common:duplicates.detected", { leadLabel })}
              </Text>
              {!isRefreshing && (
                <CreateForm
                  afterSubmit={this.getAfterSubmitAction()}
                  customButtons={this.renderAddMoreCheckbox()}
                  fields={this.getFields()}
                  onSubmit={this.onSubmit}
                  onCancel={onClose}
                />
              )}
            </div>
          </FlexCol>
        )}
        <ConfirmDuplicatesModal
          isModalOpen={isModalOpen}
          submitLead={this.submitLead}
          closeModal={this.closeModal}
          payload={payload}
          afterSubmit={onClose}
        />
      </>
    );
  }
}

export default AddLeadForm;
