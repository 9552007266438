import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { AddressField, NumberField } from "@wingmate/toolkit";
import {
  DateField,
  DateTimeField,
  MultiSelect,
  Option,
  SelectField,
  TextAreaField,
  TextField,
} from "wm-ui-toolkit";
import { PhotoUrls } from "../PhotoUrls/PhotoUrls";

export const FIELD_TYPES = [
  "address",
  "date",
  "datetime",
  "number",
  "decimal",
  "hidden",
  "integer",
  "photo",
  "select",
  "naics",
  "sic",
  "textarea",
  "text",
  "multiselect",
];

export const FIELD_SIZES = ["small", "middle", "large"];

export class InputField extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      id: PropTypes.string,
      defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
      ]),
      disabled: PropTypes.bool,
      message: PropTypes.string,
      messageType: PropTypes.string, // Deprecated, use warning/error instead
      type: PropTypes.oneOf(FIELD_TYPES),
      onChange: PropTypes.func,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      multiple: PropTypes.bool,
      size: PropTypes.oneOf(FIELD_SIZES),
      min: PropTypes.number,
      max: PropTypes.number,
      options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.any,
          })
        ),
      ]),
      warning: PropTypes.bool,
    }),
    onChange: PropTypes.func,
  };

  onChange = (data) => {
    const { field, onChange } = this.props;

    if (onChange) {
      onChange(data, field);
    }

    if (field.onChange) {
      field.onChange(data, field);
    }
  };

  renderField = () => {
    const { field } = this.props;
    const {
      defaultValue,
      disabled,
      message,
      messageType,
      multiple,
      placeholder,
      required,
      warning
    } = field;

    const baseProps = {
      id: field.id,
      onChange: this.onChange,
      defaultValue,
      disabled,
      placeholder,
      multiple,
      warning
    };

    // TODO: Double check Address Field and Duplicates
    switch (field.type) {
      case "address":
        return <AddressField {...baseProps} />;
      case "date":
        if (baseProps.defaultValue && typeof baseProps.defaultValue === "string") {
          baseProps.defaultValue = new Date(
            moment(baseProps.defaultValue).format()
          );
        }

        return <DateField {...baseProps} />;

      case "datetime":
        if (baseProps.defaultValue && typeof baseProps.defaultValue === "string") {
          baseProps.defaultValue = new Date(
            moment(baseProps.defaultValue).format()
          );
        }

        return <DateTimeField {...baseProps} />;

      case "decimal":
        return (
          <NumberField
            {...baseProps}
            size={field.size}
            min={field.min}
            helperTextLeft={message}
            status={messageType}
          />
        );
      case "number":
        return (
          <NumberField
            {...baseProps}
            min={field.min}
            size={field.size}
            helperTextLeft={message}
            status={messageType}
          />
        );
      case "integer":
        return (
          <NumberField
            {...baseProps}
            precision={0}
            min={field.min}
            size={field.size}
            helperTextLeft={message}
            status={messageType}
          />
        );

      case "photo":
        return <PhotoUrls {...baseProps} />;

      case "select":
        return (
          <SelectField {...baseProps} requireSelect={required}>
            {this.renderOptions(field.options)}
          </SelectField>
        );

      case "textarea":
        return <TextAreaField {...baseProps} />;

      case "multiselect":
        if (typeof baseProps.defaultValue === "string") {
          baseProps.defaultValue = [baseProps.defaultValue];
        }

        return (
          <MultiSelect {...baseProps} requireSelect={required}>
            {this.renderOptions(field.options)}
          </MultiSelect>
        );

      default:
        return <TextField {...baseProps} />;
    }
  };

  renderOptions = (options) => {
    return options.map((option) => {
      if (typeof option === "object" && option !== null) {
        return (
          <Option value={option.id} key={option.id}>
            {option.label}
          </Option>
        );
      } else {
        return (
          <Option value={option} key={option}>
            {option}
          </Option>
        );
      }
    });
  };

  render() {
    return this.renderField();
  }
}

export default InputField;
