import React from "react";
import PropTypes from "prop-types";
import { FileUpload } from "wm-ui-toolkit";

import "./S3Upload.scss";

export class S3Upload extends React.Component {
  static propTypes = {
    description: PropTypes.string,
    id: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    retrieveUploadUrl: PropTypes.func.isRequired,
    setLoading: PropTypes.func,
    showPreview: PropTypes.bool,
    size: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
  }

  onChange = async (files) => {
    const { onChange, retrieveUploadUrl, setLoading } = this.props;

    if (setLoading) {
      setLoading(true);
    }

    this.setState({ errorMessage: null });

    const filesWithUploadUrls = [];

    await Promise.all(
      files.map(async (file) => {
        try {
          const fileInfo = await retrieveUploadUrl(file);

          filesWithUploadUrls.push({
            ...fileInfo,
            file,
          });
        } catch (err) {
          this.setState({ errorMessage: err.message });
        }
      })
    );

    if (setLoading) {
      setLoading(false);
    }

    onChange(filesWithUploadUrls);
  };

  render() {
    const { description, id, multiple, showPreview, size } = this.props;
    const { errorMessage } = this.state;

    return (
      <div className="s3Upload">
        <FileUpload
          description={description}
          id={id}
          multiple={multiple}
          onChange={this.onChange}
          size={size}
          showPreview={showPreview}
        />
        {errorMessage && (
          <div className="s3Upload_errorMessage">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default S3Upload;
