import { action, computed, makeObservable, observable, set, toJS } from "mobx";
import { LeadLinksStore } from "./LeadLinksStore";
import { LikesStore } from "./LikesStore";
import { ContactsStore } from "./ContactsStore";
import { ActivityStore } from "./ActivityStore";
import { AttachmentsStore } from "./AttachmentsStore";
import { MergeLeadsStore } from "./MergeLeadsStore";
import { LEAD_DETAIL_TYPE } from "./LeadsStore";
import * as leadsService from "../requests/leads";
import * as leadFlagsService from "../requests/leads/flags";
import * as leadActionsService from "../requests/leads/actions";
import * as reminderService from "../requests/leads/reminders";
import * as leadPurchasesService from "../requests/leads/purchases";
import { underScoreToCamelCase } from "../utils/commons";

const ACCORDION_STATUSES_STORAGE_KEY = "accordionStatuses";
const LEAD_INFO_ACCORDION_STATUS_KEY = "LeadInfo";

export class LeadDetailsStore {
  rootStore;

  @observable leadId;

  @observable lead = {};

  @observable isLoading = false;

  @observable accordionStatuses = new Map();

  @observable isInitializing = true;

  constructor(rootStore, leadId, lead = {}) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.leadId = leadId;
    this.lead = lead;

    this.activityStore = new ActivityStore(this);
    this.leadLinksStore = new LeadLinksStore(this);
    this.likesStore = new LikesStore(this);
    // this.storageKey = `LeadDetailsStore/u${context.currentUser.id}/c${context.campaign.id}/${ACCORDION_STATUSES_STORAGE_KEY}`;
    this.attachmentsStore = new AttachmentsStore(this);
    this.mergeLeadsStore = new MergeLeadsStore(this);
    this.contactsStore = new ContactsStore(this);
    // this.initializeLeadDetail(leadId);

    this.initializeAccordionStatuses();
  }

  @computed get visibleCustomFields() {
    const { authStore } = this.rootStore;
    const { context } = authStore;
    const { campaign } = context;
    const { customFields } = campaign;
    const leadCustomFields = this.lead.attributes.customFields;

    return customFields.filter((campaignCustomField) => {
      const { depends } = campaignCustomField;

      if (!depends || depends.length === 0) {
        return true;
      }

      return depends.every((dependency) =>
        this.isDependencySatisfied(dependency, leadCustomFields[dependency.id])
      );
    });
  }

  isDependencySatisfied(dependency, value) {
    return Array.isArray(value)
      ? value.includes(dependency.value)
      : value === dependency.value;
  }

  @computed get customFieldTables() {
    const { authStore } = this.rootStore;
    const { context } = authStore;
    const { campaign } = context;
    const { customFields } = campaign;

    return customFields.filter((customField) => customField.type === "table");
  }

  @computed get storageKey() {
    const { context } = this.rootStore;

    return `LeadDetailsStore/u${context.currentUser.id}/c${context.campaign.id}/${ACCORDION_STATUSES_STORAGE_KEY}`;
  }

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  async loadLead() {
    const newLead = await this.getLeadDetailsById(this.leadId);

    this.setLead(newLead);
    this.setIsInitializing(false);
  }

  @action
  async getLeadDetailsById(leadId) {
    const lead = this.rootStore.leadsStore.leads.find(
      (lead) => lead.id === leadId
    );

    let newLead;

    if (lead) {
      if (lead.type !== LEAD_DETAIL_TYPE) {
        newLead = await this.rootStore.leadsStore.retrieveLeadDetailAsync(
          leadId
        );
      } else {
        newLead = lead;
      }
    } else {
      newLead = await this.rootStore.leadsStore.retrieveLeadDetailAsync(
        leadId
      );
    }

    return newLead;
  }

  @action
  initializeAccordionStatuses() {
    const accordionStatusesFromStorageJson = this.getFromLocalStorage();

    if (accordionStatusesFromStorageJson) {
      try {
        const accordionStatuses = JSON.parse(accordionStatusesFromStorageJson);
        this.setAccordionStatuses(accordionStatuses);
      } catch (err) {
        this.setDefaultAccordionStatuses();
      }
    } else {
      this.setDefaultAccordionStatuses();
    }
    this.setToLocalStorage(this.accordionStatuses);
  }

  getFromLocalStorage() {
    return localStorage.getItem(this.storageKey);
  }

  setDefaultAccordionStatuses = () => {
    set(this.accordionStatuses, LEAD_INFO_ACCORDION_STATUS_KEY, true);
  };

  @action
  setAccordionStatuses = (accordionStatus) => {
    const oldAccordionStatuses = Object.fromEntries(
      toJS(this.accordionStatuses)
    );
    const newAccordionStatuses = {
      ...oldAccordionStatuses,
      ...accordionStatus,
    };

    Object.entries(newAccordionStatuses).forEach((keyValuePair) =>
      set(this.accordionStatuses, keyValuePair[0], keyValuePair[1])
    );

    this.setToLocalStorage();
  };

  setToLocalStorage() {
    localStorage.setItem(
      this.storageKey,
      JSON.stringify(Object.fromEntries(toJS(this.accordionStatuses)))
    );
  }

  @action
  setIsInitializing = (newIsInitializing) => {
    this.isInitializing = newIsInitializing;
  };

  @action
  setLeadId = (newLeadId) => {
    this.leadId = newLeadId;
  };

  @action
  setLead = (newLead) => {
    this.lead = newLead;
  };

  @action
  modifyFieldOnLead = (field, value) => {
    this.lead.attributes[field] = value;
  };

  @action
  setLeadIdAndReloadStores = async (newLeadId) => {
    const { authStore } = this.rootStore;
    const { context } = authStore;
    const { currentUser } = context;
    const isNotDevEnvironment = process.env.NODE_ENV !== "development";
    this.setLeadId(newLeadId);

    await this.rootStore.leadsStore.retrieveLeadDetailAsync(
      newLeadId
    );

    await this.loadLead();
    await this.likesStore.retrieveLikesForLeadAsync();
    await this.attachmentsStore.getAttachmentsAsync();
    await this.activityStore.retrieveActivitiesForLeadAsync();
    await this.contactsStore.getContacts();
    await this.rootStore.approvalRequestsStore.retrieveApprovalRequestsForLeadAsync(
      this.leadId
    );
    await this.leadLinksStore.retrieveLinksForLeadAsync();

    await this.rootStore.territoriesStore.retrieveLeadTerritoriesForLeadAsync(
      this.rootStore.campaignId,
      this.leadId,
      currentUser
    );

    if (isNotDevEnvironment) {
      await this.rootStore.duplicatesStore.retrieveDuplicatesForLeadAsync(
        this.leadId
      );
    }
  };

  @action
  initialLeadsStoreUpdate(payload) {
    Object.entries(payload.lead).map((attribute) => {
      const field = underScoreToCamelCase(attribute[0]);
      const value = attribute[1];
      this.modifyFieldOnLead(field, value);
    });
  }

  @action
  async updateLead(payload) {
    this.setIsLoading(true);
    const { leadsStore } = this.rootStore;
    const leadBeforeUpdate = JSON.stringify(this.lead);
    this.initialLeadsStoreUpdate(payload);

    try {
      const lead = await leadsStore.updateLead(this.leadId, payload);
      this.setLead(lead);
      this.setIsLoading(false);

      return true;
    } catch (err) {
      this.setLead(JSON.parse(leadBeforeUpdate));
      leadsStore.modifyLeadInCache(this.leadId, JSON.parse(leadBeforeUpdate));
      let message;
      if (err.response) {
        message = Object.values(err.response.data.errors).join(" ");
      } else {
        message =
          "Something went wrong. Please contact support for assistance.";
      }
      this.rootStore.bannerStore.addBanner(
        "red",
        "Lead update failed",
        message
      );
      this.setIsLoading(false);

      return false;
    }
  }

  @action
  initialCustomFieldUpdate = (customFieldId, newValue) => {
    const newCustomFields = this.lead.attributes.customFields;
    newCustomFields[customFieldId] = newValue;
    this.modifyFieldOnLead("customFields", newCustomFields);
  };

  @action
  updateLeadCustomFieldsAsync = async (customFieldId, newValue) => {
    const { leadsStore } = this.rootStore;
    let cfBeforeUpdate = JSON.stringify(
      this.lead.attributes.customFields[customFieldId]
    );
    this.initialCustomFieldUpdate(customFieldId, newValue);

    try {
      const lead = await leadsStore.updateLeadCustomFieldsAsync(
        this.lead.id,
        customFieldId,
        newValue
      );
      this.setLead(lead);

     await this.rootStore.approvalRequestsStore.retrieveApprovalRequestsForLeadAsync(
        this.leadId
      );
    } catch (err) {
      cfBeforeUpdate = cfBeforeUpdate ? JSON.parse(cfBeforeUpdate) : cfBeforeUpdate;
      this.initialCustomFieldUpdate(customFieldId, cfBeforeUpdate);
      let message;
      if (err.response) {
        message = Object.values(err.response.data.errors).join(" ");
      } else {
        message =
          "Could not update that custom field. Please contact support for assistance.";
      }

      this.rootStore.bannerStore.addBanner(
        "red",
        "Custom field update failed",
        message
      );
    }
  };

  @action
  async createPurchase(payload) {
    const leadId = this.lead.id;

    await leadPurchasesService.postPurchase(leadId, payload);

    await this.refreshLeadDetails();
  }

  @action
  async updatePurchase(purchaseId, payload) {
    const leadId = this.lead.id;

    try {
      await leadPurchasesService.putPurchase(leadId, purchaseId, payload);

      await this.refreshLeadDetails();
    } catch (err) {
      this.rootStore.bannerStore.addBanner(
        "red",
        "Updating purchase failed",
        "Plase reload and try again or contact support."
      );
    }
  }

  @action
  async archiveLead(leadPayload, reminderPayload, leadLabel) {
    if (reminderPayload) {
      try {
        await reminderService.postReminder(this.leadId, reminderPayload);
      } catch (err) {
        this.rootStore.bannerStore.addBanner(
          "red",
          "Reminder failed",
          `Reminder to unarchive ${leadLabel} could not be created.`
        );
      }
    }

    try {
      await leadsService.putLead(this.leadId, leadPayload);
      this.rootStore.bannerStore.addBanner(
        "green",
        "Archive successful",
        `This ${leadLabel} has been archived. A reminder to unarchive the ${leadLabel} has been made.`
      );
    } catch (err) {
      this.rootStore.bannerStore.addBanner(
        "red",
        "Archive failed",
        "The status could not be updated."
      );
    }
    try {
      await this.refreshLeadDetails();
    } catch (err) {
      this.rootStore.bannerStore.addBanner(
        "red",
        "Changes not visible",
        "Page could not be refreshed. Refresh your page to see changes."
      );
    }
  }

  @action
  async deleteLead() {
    this.setIsLoading(true);
    await leadsService.deleteLead(this.leadId);
    this.setIsLoading(false);
  }

  @action flagLead = async () => {
    try {
      await leadFlagsService.postFlag(this.leadId);

      await this.refreshLeadDetails();
    } catch (err) {
      let message;
      if (err.response) {
        message = Object.values(err.response.data.errors).join(" ");
      } else {
        message =
          "The lead could not be flagged. Please contact support for assistance.";
      }

      this.rootStore.bannerStore.addBanner(
        "red",
        "Failed to flag lead",
        message
      );
    }
  };

  @action removeFlags = async () => {
    try {
      await leadFlagsService.deleteFlags(this.lead.id);

      await this.refreshLeadDetails();
    } catch (err) {
      this.rootStore.bannerStore.addBanner(
        "red",
        "Failed to remove flags",
        "Could not remove flags. Please contact support for assistance."
      );
    }
  };

  @action requestDetails = async (payload) => {
    try {
      await leadActionsService.postLeadAction(this.leadId, payload);

      await this.refreshLeadDetails();
    } catch (err) {
      let message;
      if (err.response) {
        message = Object.values(err.response.data.errors).join(" ");
      } else {
        message =
          "Could not request details at the moment. Please contact support for assistance.";
      }

      this.rootStore.bannerStore.addBanner(
        "red",
        "Detail request failed",
        message
      );
    }
  };

  @action refreshLeadDetails = async () => {
    const lead = await this.rootStore.leadsStore.retrieveLeadDetailAsync(
      this.leadId
    );

    await this.activityStore.retrieveActivitiesForLeadAsync();
    await this.rootStore.approvalRequestsStore.retrieveApprovalRequestsForLeadAsync(
      this.leadId
    );

    this.setLead(lead);
  };

  @action removeOwner = async (idToRemove) => {
    const newOwnerIds = this.lead.attributes.ownerIds.filter(
      (ownerId) => ownerId !== idToRemove
    );

    await this.updateLead({
      lead: {
        owner_ids: newOwnerIds,
      },
    });
  };

  @action getLeadDetails = async (leadId) => {
    const lead = await leadsService.getLeadDetails(leadId);
    this.setLead(lead);
  };
}

export default LeadDetailsStore;
